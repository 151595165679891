import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadCategory, setCategoryValue } from 'Store/Actions/marketplace'

import {
  getCategory,
  getAllCategories,
  getLoadingCategory,
  getCategoryValue,
  getSsrPreloadedCategory,
} from 'Store/Selectors/marketplace'

import Category from './Category'

const selector = createStructuredSelector({
  category: getCategory,
  allCategories: getAllCategories,
  isLoadingCategory: getLoadingCategory,
  categoryValue: getCategoryValue,
  ssrPreloadedCategory: getSsrPreloadedCategory,
})

const actions = {
  onLoadCategory: loadCategory,
  onSetCategoryValue: setCategoryValue,
}

export default connect(selector, actions)(Category)
