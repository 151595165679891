import styled from 'styled-components'
import { maxWidth } from 'styled-system'

import { Flex, Box } from 'Components/UI'

export const Container = styled(Flex).attrs({
  width: 1,
  py: 24,
  flexDirection: ['column', 'column', 'row'],
})`
  height: fit-content;
`

export const LeftSide = styled(Flex).attrs({
  width: 1,
  pb: 4,
  maxWidth: ['100%', '100%', 330],
})`
  ${maxWidth};
`

export const Content = styled(Box).attrs({
  width: 1,
  pl: [0, 0, 50],
})``

export const LoaderHolder = styled(Flex).attrs({
  width: 1,
  py: 3,
})`
  height: 60px;
  position: relative;
`
