import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router'

import get from 'lodash/get'
import size from 'lodash/size'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'

import { PUBLIC_PATHS } from 'Constants/paths'
import { processBuildCategoryValue } from 'Services/Utils/merchants'

import {
  SubCategories,
  CategoryDetail,
  FilterCategories,
} from 'Components/Blocks'

import { Loader } from 'Components/UI'

import { loadCategory, setCategoryValue } from 'Store/Actions/marketplace'

import { Container, LeftSide, Content, LoaderHolder } from './styles'

const getCategoryValueFromUrl = ({ categories, match }) => {
  const primaryCategorySlug = get(match, 'params.primaryCategorySlug')
  const secondaryCategorySlug = get(match, 'params.secondaryCategorySlug')

  let categoryValueFromUrl = null

  if (secondaryCategorySlug) {
    forEach(categories, category => {
      if (categoryValueFromUrl) return
      categoryValueFromUrl = find(get(category, 'category_2', []), [
        'bucket_slug',
        secondaryCategorySlug,
      ])
    })
  } else if (primaryCategorySlug) {
    categoryValueFromUrl = find(categories, [
      'bucket_slug',
      primaryCategorySlug,
    ])
  }

  return categoryValueFromUrl
}

const getNextCategoryValue = ({ categoryValueFromUrl }) => {
  const categoryBucketType = get(categoryValueFromUrl, 'bucket_type')
  const categoryId = get(categoryValueFromUrl, 'bucket_id')

  let nextCategoryValue = null

  if (categoryBucketType && categoryId) {
    nextCategoryValue = {
      label: categoryValueFromUrl.bucket_name,
      labelIcon: true,
      labelIconActive: false,
      metadata: { type: 'h2' },
      type: 'categories',
      value: categoryValueFromUrl,
    }
  }

  return nextCategoryValue
}

function Category({
  allCategories,
  categoryValue,
  isLoadingCategory,
  ssrPreloadedCategory,
  onLoadCategory,
  onSetCategoryValue,
}) {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const [categoryValueState, setCategoryValueState] = useState(categoryValue)
  const categories = get(allCategories, 'categories', [])

  const handleLoadCategory = () => {
    if (!size(categories)) return
    if (ssrPreloadedCategory) return

    const categoryValueFromUrl = getCategoryValueFromUrl({ categories, match })
    const nextCategoryValue = getNextCategoryValue({ categoryValueFromUrl })

    onSetCategoryValue(nextCategoryValue)

    const categoryBucketType = get(categoryValueFromUrl, 'bucket_type')
    const categoryId = get(categoryValueFromUrl, 'bucket_id')

    if (categoryBucketType && categoryId) {
      onLoadCategory({
        categoryBucketType,
        categoryId,
        ssr: false,
      })
    }
  }

  const handleSelectCategory = () => {
    if (isEqual(categoryValue, categoryValueState)) {
      return null
    }

    setCategoryValueState(categoryValue)

    let path = `${PUBLIC_PATHS.CATEGORIES}${location.hash}`

    if (categoryValue) {
      const selectedCategoryTree = processBuildCategoryValue(
        categoryValue,
        categories,
      )
      const primaryCategorySlug = get(selectedCategoryTree, 'value.bucket_slug')
      const secondaryCategorySlug = get(
        selectedCategoryTree,
        'value.category_2.bucket_slug',
      )

      path = PUBLIC_PATHS.PRIMARY_CATEGORY(primaryCategorySlug)

      if (secondaryCategorySlug) {
        path = PUBLIC_PATHS.SECONDARY_CATEGORY(
          primaryCategorySlug,
          secondaryCategorySlug,
        )
      }
    }

    history.push(path)

    return null
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    handleLoadCategory()
  }, [location.pathname, categories])

  useEffect(() => {
    handleSelectCategory()
  }, [categoryValue])

  return (
    <Container>
      <LeftSide>
        <FilterCategories categories={categories} />
      </LeftSide>

      <Content>
        {isLoadingCategory ? (
          <LoaderHolder>
            <Loader size={26} />
          </LoaderHolder>
        ) : (
          <Switch>
            <Route exact path={PUBLIC_PATHS.PRIMARY_CATEGORY()}>
              <SubCategories />
            </Route>
            <Route exact path={PUBLIC_PATHS.SECONDARY_CATEGORY()}>
              <CategoryDetail />
            </Route>
          </Switch>
        )}
      </Content>
    </Container>
  )
}

Category.fetchData = (store, match) => {
  const { dispatch, getState } = store
  const { marketplace } = getState()

  const categories = get(marketplace, 'allCategories.categories', [])
  const categoryValueFromUrl = getCategoryValueFromUrl({ categories, match })
  const nextCategoryValue = getNextCategoryValue({ categoryValueFromUrl })

  dispatch(setCategoryValue(nextCategoryValue))

  const categoryBucketType = get(categoryValueFromUrl, 'bucket_type')
  const categoryId = get(categoryValueFromUrl, 'bucket_id')

  if (categoryBucketType && categoryId) {
    return dispatch(
      loadCategory({
        categoryBucketType,
        categoryId,
        ssr: true,
      }),
    )
  }

  return null
}

Category.defaultProps = {
  allCategories: {},
  categoryValue: null,
  isLoadingCategory: false,
}

Category.propTypes = {
  allCategories: PropTypes.object,
  categoryValue: PropTypes.object,
  isLoadingCategory: PropTypes.bool,
  ssrPreloadedCategory: PropTypes.bool.isRequired,
  onLoadCategory: PropTypes.func.isRequired,
  onSetCategoryValue: PropTypes.func.isRequired,
}

export default Category
